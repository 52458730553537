import axios from "axios";
import { loginResponse } from "@/services/api-schema";
import { KUrls } from "@/helpers/constants";
import { saveAuthToken } from "../../helpers/authHeader";

export const authService = {
  login,
  logout,
  getLoginQr,
  listenQrStatus,
  getEasedLoginURL,
  completeEasedLogin
};

const authEndpoint = KUrls.AUTHENTICATION_ENDPOINT || "/authenticate";

async function login(username, password) {
  const response = await axios.post(
    `${KUrls.BASE_REST_URL}${authEndpoint}`,
    { username, password },
    {
      "Content-Type": "application/json",
    }
  );
  const data = await handleResponse(response);
  if (data[loginResponse.token]) {
    // localStorage.setItem("user", data[loginResponse.token]);
    saveAuthToken(data[loginResponse.token]);
  }
  if (data[loginResponse.userSchemas]) {
    localStorage.setItem(
      "user_schemas",
      JSON.stringify(data[loginResponse.userSchemas])
    );
  }
  return data;
}

async function getLoginQr(pageUrl, qrSize, qrMime) {
  const response = await axios.get(`${KUrls.BASE_REST_URL}${authEndpoint}/qr`, {
    headers: {
      //   "Content-Type": "application/json",
      // Authorization: authorization,
    },
    params: {
      qrSize: qrSize,
      qrSpecs: null,
      qrType: qrMime,
      pageUrl,
    },
  });
  const data = response.data;
  return data;
}

function listenQrStatus(qrId) {
  const evtSource = new EventSource(
    `${KUrls.DOMAIN}${KUrls.BASE_REST_URL}${authEndpoint}/qr/listen?qrId=${qrId}`
  );
  evtSource.onmessage = (e) => console.log(e);
  return evtSource;
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("user_schemas");
}

function handleResponse(response) {
  // This function is never actually called in case of error, because of axios interceptor declared in main.js
  const data = response && response.data;
  if (!response.status && response.status != 200) {
    if (response.status === 401) {
      // unauthorized
    }
    const error = (data && data.message) || response.statusText;
    console.log(error);
    return Promise.reject(error);
  }

  return data;
}

async function getEasedLoginURL(pageUrl, redirectPath) {
  const response = await axios.get(`${KUrls.BASE_REST_URL}${authEndpoint}/el`, {
    headers: {
    },
    params: {
      pageUrl,
      redirectPath,
    },
  });
  const data = response.data;
  return data;
}

async function completeEasedLogin(responseBase64, signatureBase64, signatureAlgorithm) {
  const response = await axios.get(`${KUrls.BASE_REST_URL}${authEndpoint}/el/complete`, {
    headers: {
    },
    params: {
      responseBase64,
      signatureBase64,
      signatureAlgorithm
    },
  });
  const data = response.data;
  if (data[loginResponse.token]) {
    saveAuthToken(data[loginResponse.token]);
  }
  return data;
}