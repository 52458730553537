<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered is-mobile">
          <div
            class="column is-5-tablet is-4-desktop is-3-widescreen is-8-mobile"
          >
            <b-image :src="logoDir" alt="Logo" class="block"></b-image>
            <!-- <h3 class="title">Login</h3>
            <div class="is-divider"></div>
            <h2 class="subtitle">{{ $t("login.page.subtitle") }}</h2> -->

            <div class="block">

              <login-qr v-if="$config.loginQR"></login-qr>

              <!-- <div v-else-if="$config.loginEL"> -->
              <!-- v-if="!isELComplete" -->
              <div v-else-if="$config.loginEL" class="block">
                <figure v-if="!isELComplete()" class="image withMobysignButton" @click="getEasedLoginURL">
                  <img v-show="!loading" src="@/assets/btn_mobysign_signin_normal.svg" />
                  <b-loading :active="loading" :is-full-page="false"></b-loading>
                </figure>
                
                <b-notification
                    type="is-danger"
                    aria-close-label="Close notification"
                    role="alert"
                    v-if="processError"
                  >
                    {{ lastErrorMessage }}
                </b-notification>

                <!-- <b-button
                  v-if="!isELComplete()"
                  native-type="button"
                  :loading="loading"
                  @click="getEasedLoginURL"
                  >Log in with Mobysign</b-button
                > -->
                <b-loading v-else :is-full-page="true" v-model="loading"></b-loading>
              </div>

              <LoginForm
                v-else
                :redirection="redirect"
                :loading="getStatus.loggingIn"
                @login="onLogin"
              >
                <template #error>
                  <b-notification
                    type="is-danger"
                    aria-close-label="Close notification"
                    role="alert"
                    v-if="getStatus.error"
                  >
                    {{ $t("login.error.login_failed_wrong_user_pwd") }}
                  </b-notification>
                </template>
              </LoginForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoginForm from "@/components/login/LoginForm.vue";
import LoginQr from "../components/login/LoginQr.vue";
import { authService, loginResponse } from "../services";

const mode = process.env.VUE_APP_MODE;

export default {
  name: "Login",
  components: { LoginForm, LoginQr },
  data() {
    return {
      redirect: this.$route.query.redirect || "/dashboard",
      loading: false,
      processError: false,
      lastErrorMessage: null,
    };
  },
  computed: {
    ...mapGetters("users", ["getStatus"]),
    logoDir() {
      if (mode === "eurotext") {
        return require("@/assets/eurotext_logo_optimized.svg");
      } else if (mode === "mobysign") {
        return require("@/assets/mobysign_logo_optimized.svg");
      } else {
        return require("@/assets/logo_login.svg");
      }
    },
  },
  // watch: {
  //   getStatus: function(){
  //     if(this.getStatus)
  //   }
  // }
  methods: {
    ...mapActions("users", ["login"]),
    onLogin(username, password) {
      const redirection = this.redirect;
      if (username && password) {
        this.login({ username, password, redirection });
      }
    },
    async getEasedLoginURL() {
      this.loading = true;
      this.processError = false;
      this.lastErrorMessage = null;
      const res = await authService.getEasedLoginURL(
          window.location.href,
          "login"
        );
      this.loading = false;
      window.location.replace(res.url);
    },
    async completeEasedLogin() {
      this.loading = true;
      try {
        const res = await authService.completeEasedLogin(
            this.$route.query.responseBase64,
            this.$route.query.signatureBase64,
            this.$route.query.signatureAlgorithm
          );
        const data = res;
        this.$store.commit("users/loginSuccess", {
          schemas: data[loginResponse.userSchemas],
          user: data[loginResponse.username],
          subNickname: data[loginResponse.subNickname],
        });
        this.$router.push(this.redirect);
      } catch (error) {
        const errorObject  = {
          message: (error.response.data && error.response.data.message) || error.response.statusText,
          code: (error.response.data && error.response.data.code) || -10001 };

        if (errorObject.code == -15017) {
          // Mobile number mismatch
          this.lastErrorMessage = this.$t("login.error.mobile_number_mismatch");
        } else {
          this.lastErrorMessage = this.$t("login.error.login_failed");
        }
        this.processError = true;
        console.error(errorObject.code);
        console.error("Error while completing EL: " + errorObject.message);
      }
      this.loading = false;
    },
    isELComplete() {
      return this.$route.query.responseBase64 && this.$route.query.responseBase64.length > 0 && !this.processError;
    },
  },
  mounted() {
    if (this.isELComplete()) {
      this.completeEasedLogin()
    }
  }
};
</script>

<style>
</style>
<style scoped>
body::after{
	/* Preload images */
    position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
    content:url("~@/assets/btn_mobysign_signin_normal.svg")
    	url("~@/assets/btn_mobysign_signin_focused.svg")
    	url("~@/assets/btn_mobysign_signin_pressed.svg")
    	url("~@/assets/btn_mobysign_signin_disabled.svg");
}

.withMobysignButton {
  cursor: pointer;
  background-image: url("~@/assets/btn_mobysign_signin_normal.svg");
  background-size: contain;
}

.withMobysignButton > img {
	visibility: hidden;
	/*width: 100%;*/
	margin: 0;
	padding: 0;
}

.withMobysignButton:hover {
	background-image: url("~@/assets/btn_mobysign_signin_focused.svg");
}

.withMobysignButton:active {
	background-image: url("~@/assets/btn_mobysign_signin_pressed.svg");
}

.withMobysignButton:disabled {
	background-image: url("~@/assets/btn_mobysign_signin_disabled.svg");
}
</style>